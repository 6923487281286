<template>
   <div style="padding:20px;background: #fff;">
       <div style="padding-bottom:20px;">
           <p style="margin-top:0; margin-bottom:10px;font-size:28px;">{{industryChainName}}</p>
           <span>{{industryClassName}}</span>
       </div>
        <el-row :gutter="20">
            <el-col :span="3"><div class="grid-title">产业链结构</div></el-col>
            <el-col :span="8"><div class="grid-title">产品服务</div></el-col>
            <el-col :span="3"><div class="grid-title">企业名称</div></el-col>
            <el-col :span="5"><div class="grid-title">企业经营</div></el-col>
            <el-col :span="3"><div class="grid-title">注册资本</div></el-col>
            <el-col :span="2"><div class="grid-title">法人</div></el-col>
        </el-row>
        <el-row :gutter="20" v-for="(item,index) in industryChain" :key="item.id">
           <el-col :span="3">
               <div class="grid-content-h">
                   <span class="grid-struc" :style="{ backgroundColor: colors[index].replace('@opacity','0.8') }">{{item.name}}</span>
               </div>
            </el-col>
            <el-col :span="8">
                <el-scrollbar>
                    <div  class="grid-content-h" :class="{'grid-self':!item.isExistSubOfservice,'grid-sub':item.isExistSubOfservice}" :style="{width:item.isExistSubOfservice && item.children.length>4? (item.children.length/4*100-5)+'%':'100%' }" >
                        <div v-for="service in item.children" :key="service.id" class="grid-content-ser" >
                            <span class="grid-struc" @click="getEpAndPersonOfServiceFun(item.id,service.id)" :style="{ backgroundColor: colors[index].replace('@opacity','0.8') }">{{service.name}}</span>
                            <div v-for=" subService in service.children" :key="subService.id" style="position: relative;margin:0 5px;">
                                <div style="width:1px;height:10px; background:#fff;margin-left:50%;"></div> 
                                <p class="sub-ser" :style="{ backgroundColor: colors[index].replace('@opacity','1') }">{{subService.name}}</p>
                                <el-popover placement="right" :width="400" trigger="click" v-if='subService.children'>
                                    <template #reference>
                                    <i class="iconfont ec-icon-circle-plus-outline sub-ser-add"></i>
                                    </template>
                                    <el-scrollbar>
                                        <span>{{subService.name}}</span>
                                        <div class="grid-content-h grid-self">
                                            <div v-for="subSer in subService.children" :key="subSer.id" class="grid-content-ser" >
                                                <span class="grid-struc"  :style="{ backgroundColor: colors[index].replace('@opacity','0.8') }">{{subSer.name}}</span>
                                                <div v-for=" ssubSer in subSer.children" :key="ssubSer.id" style="position: relative;margin:0 5px;">
                                                    <div style="width:1px;height:10px; background:#fff;margin-left:50%;"></div> 
                                                    <p class="sub-ser" :style="{ backgroundColor: colors[index].replace('@opacity','1') }">{{ssubSer.name}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </el-scrollbar>
                                </el-popover>
                            </div>
                        </div>   
                    </div>
                </el-scrollbar>
            </el-col>
            <el-col :span="13" style="position: relative;">
                 <div style="position: absolute;top: 10px;">
                    <span style="display: inline-block;height:20px;padding:0 10px;line-height:20px;margin-left:10px;border-radius:2px;" :style="{backgroundColor: colors[index].replace('@opacity','0.8') }">{{item.children.filter(t=>t.isSel)[0]?.name}}</span>
                </div>
                <el-dropdown v-if='item.children.filter(t=>{return t.isSel }).length>0' style="position: absolute;right:10px;top: 10px;" @command='(val)=>{item.epPageIndex=1;item.epSort=val;  getEpListOfServiceFun(item.id,item.children.filter(t=>{return t.isSel })[0]?.id)}'>
                    <span class="el-dropdown-link">
                    排序
                    <i class="iconfont ec-icon-arrow-bottom" style="font-size:20px;vertical-align:middle;margin-left:-5px;">
                    </i>
                    </span>
                    <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item command='ent_name'>名称</el-dropdown-item>
                        <el-dropdown-item command="start_date">成立日期</el-dropdown-item>
                        <el-dropdown-item command="registeredcapital">注册资本</el-dropdown-item>
                    </el-dropdown-menu>
                    </template>
                </el-dropdown>
                <div v-loading="item.loading" element-loading-text="正在加载中..." class="grid-content-h" :style="{ backgroundColor: colors[index].replace('@opacity','0.1') }" >
                    <div v-for='ep in item.epList' :key="ep.companyId" class="grid-ep" :style="{borderColor:colors[index].replace('@opacity','0.1') }">
                        <div  class="grid-ep-name" :style="{backgroundColor:colors[index].replace('@opacity','0.1'),borderColor:colors[index].replace('@opacity','0.1')  }">
                            <el-link :underline="false" @click.prevent="push(ep.companyId,ep.province)">{{ep.name}}</el-link>
                            
                        </div>
                        <div class="grid-ep-content">{{ep.scope}}</div>
                        <div  class="grid-ep-info" >
                            {{ep.registeredcapital?ep.registeredcapital+'万'+ep.capitaltype:'-'}}
                        </div>
                        <div  class="grid-ep-info" style="width:calc(14.4% - 10px);" >
                            {{ep.legalPerson}}
                        </div>
                    </div>
                    <el-pagination
                        small
                        @current-change="(val) => {item.epPageIndex=val; getEpListOfServiceFun(item.id,item.children.filter(t=>{return t.isSel })[0].id)}"
                        :current-page="item.epPageIndex"
                        :page-size="item.epPageSize"
                        :hide-on-single-page="true"
                        layout="->, prev, pager, next"
                        :total="item.epCount">
                    </el-pagination>
                </div>
            </el-col>
            <!-- <el-col :span="5" style="position: relative;">
                 <div style="position: absolute;top: 10px;">
                    <span style="display: inline-block;height: 20px;padding: 0 10px;line-height: 20px;margin-left: 10px;border-radius: 2px;" :style="{ backgroundColor: colors[index].replace('@opacity','0.8') }">{{item.children.filter(t=>t.isSel)[0]?.name}}</span>
                </div>
                <span v-if='item.personList?.length>0' @click="openDrawerFun(item.id)" style="position: absolute;top: 10px;right:10px;color: #3F94FD;cursor: pointer;">更多</span>
                <div class="grid-content-h" :style="{ backgroundColor: colors[index].replace('@opacity','0.1') }">
                    <div v-for='person in item.personList' :key="person" class="grid-ex" >
                        <div  class="grid-ex-img" > 
                            <el-image :src='person.photoUrl' >
                            <template #error>
                                <div class="image-slot">
                                <i class="el-icon-picture"></i>
                                </div>
                            </template>
                            </el-image>
                        </div>
                        <div class="grid-ex-content">
                            <span style="color: #00BFFF;">{{person.name}}</span>
                            <br>
                            {{person.profile}}
                        </div>
                    </div>
                </div>
            </el-col> -->
        </el-row>
        <!-- <el-drawer v-model="drawer" :title="drawerTitle" direction="rtl" >
            <el-scrollbar class="infinite-list-wrapper">
                <ul>
                    <li v-for="person in personList" :key="person">
                        <div class="list-item">
                             <div >
                                 <el-image :src='person.photoUrl' style="width:100px;height:130px;">
                                    <template #error>
                                        <div class="image-slot">
                                        <i class="el-icon-picture"></i>
                                        </div>
                                    </template>
                                </el-image>
                            </div>
                            <div style="padding-left:10px;">
                                <span class="fontsize16" style="color: #00BFFF;font-weight: bold;">{{person.name}}</span>
                                <br>
                                <span>出生日期：{{person.birthday}}</span>
                                <br>
                                <span>学历：{{person.education}}</span>
                                <br>
                                <span>专业：{{person.subject}}</span>
                            </div>
                        </div>
                        <p>{{person.profile}}</p>
                    </li>
                </ul>
                <div class="list-loading">
                    <p  style="color:#3F94FD;cursor: pointer;display:inline-block;" v-if="personCount>personList.length" @click='openDrawerFun(-1)' >加载更多</p>
                    <p  style="color:#C03639;" v-if="personCount<=personList.length">没有更多了</p>
                </div>
            </el-scrollbar>
        </el-drawer> -->
    </div>
</template>
<script>
import {getServicesOfIndustryChain,getEpListOfService,getIndustryChainInfo,getIndustryClassInfo} from '@/http/industryChain'
import {addUserLog} from '@/http/basicsApi.js'
import { ref } from '@vue/reactivity';
import { useRoute, useRouter } from 'vue-router';
export default {
    setup() {
        let colors=ref(['rgba(116,184,255,@opacity)','rgba(254,189,73,@opacity)','rgba(110,199,193,@opacity)','rgba(153,50,204,@opacity)','rgba(128,128,0,@opacity)']);
        const route=useRoute();//当前路由
        const router=useRouter();
        let drawer=ref(false);//右侧抽屉是否打开
        let industryChain=ref(null);//产业链
        let industryChainName=ref('');//产业链名称
        let industryClassName=ref('');//产业分类名称
        //获取产业链的信息
        const getIndustryChainInfoFun=async()=>{
            let res= await getIndustryChainInfo(route.query.id);
            console.log(res);
            if(res.code==20000){
                industryChainName.value=res.data.name;
                getIndustryClassInfoFun(res.data.industryClassId);
            }
        }
        //获取产业链的产业分类
        const getIndustryClassInfoFun=async(classId)=>{
            let res= await getIndustryClassInfo(classId);
            console.log(res);
            if(res.code==20000){
                industryClassName.value=res.data.fullTitle;
            }
        }
        ///获取产业链的子链及产品服务
        const getServicesOfIndustryChainFun=async()=>{
            let res=await getServicesOfIndustryChain(route.query.id);
            console.log(res);
            if(res.code==20000){
                industryChain.value=res.data.children;
                industryChain.value.forEach(element => {
                    element.isExistSubOfservice=element.children.filter(t=>t.children).length>0?true:false
                    if(element.children && element.children.length>0)
                    {
                        element.children[0].isSel=true;
                        //获取企业
                        element.epPageIndex=1;
                        element.epPageSize=5;
                        element.epSort='ent_name';//默认名称排序
                        getEpListOfServiceFun(element.id,element.children[0].id);
                        //获取专家
                        //getPersonListOfServiceFun(element.id,element.children[0].id,2,1,1);
                    }
                });
                
            }
        }
        const getEpAndPersonOfServiceFun=(subChainId,serviceId)=>{
            //设置被选择的产品服务
            let subChainAry=industryChain.value.filter(t=>t.id==subChainId);
            let selServiceAry=subChainAry[0].children.filter(t=>{return t.isSel });
            if(selServiceAry.length>0){
                selServiceAry[0].isSel=false;
            }
            subChainAry[0].children.filter(t=>t.id==serviceId)[0].isSel=true;
            //获取企业
            subChainAry[0].epPageIndex=1;
            subChainAry[0].epCount=0;
            subChainAry[0].epSort='ent_name';
            console.log(subChainAry);
            console.log(industryChain.value);
            getEpListOfServiceFun(subChainId,serviceId)
            //获取专家
            //getPersonListOfServiceFun(subChainId,serviceId,2,1,1);
        }
        //获取产品服务的企业
        const getEpListOfServiceFun=async(subChainId, serviceId)=>{
            let subChain=industryChain.value.filter(t=>t.id==subChainId)[0];
            subChain.loading=true;
            console.log(subChain);
            let res=await getEpListOfService(serviceId,'0',subChain.epPageSize,subChain.epPageIndex,subChain.epSort,subChain.epSort=='registeredcapital'?"desc":'asc');
            console.log(res);
            if(res.code==20000){
                subChain.epList=res.data.dataList;
                subChain.epCount=res.data.totalCount;
                subChain.loading=false;
            }
            else{
                subChain.epList=[];
                subChain.epCount=0;
                subChain.loading=false;
            }
            console.log(industryChain.value);
        }
        const push=(companyId,province)=>{
            const { href } = router.resolve({
                path: '/ep/epAnalysis',
                query: {
                    id: companyId,
                    p:province
                }
            });
            window.open(href, "_blank");
        }
        /*
        //获取产品服务的专家列表
        //type:1 表示只取前两个专家列表，存放在产业链下，type：2 表示获取产品服务的专家列表并分页
        const getPersonListOfServiceFun=async(subChainId, serviceId,pageSize,pageIndex,type)=>{
            
            let res=await getPersonListOfService(serviceId,pageSize,pageIndex);
            console.log(res);
            if(res.code==20000){
                if(type==1){
                    let subChain=industryChain.value.filter(t=>t.id==subChainId)[0];
                    subChain.personList=res.data.dataList;
                }else{
                    personList.value=[...personList.value, ...res.data.dataList];
                    personCount.value=res.data.totalCount;
                }
                
            }
            else{
                if(type==1){
                    industryChain.value.filter(t=>t.id==subChainId)[0].personList=[];
                }
                else{
                    personList.value=[];
                    personCount.value=0;
                }
            }
            console.log(industryChain.value);
        }

        //打开右侧抽屉 某个产品服务的全部专家列表，
        let drawerTitle=ref('');//右侧抽屉的标题
        let personList=ref([]);//专家列表
        let personCount=ref(0);//专家列表个数
        let pageIndex=ref(1);//页数
        let pageSize=ref(5);//每页显示的条数
        let selServiceId=ref('');//选中的产品服务ID
        const openDrawerFun=(subChainId)=>{
            if(subChainId==-1){
                 pageIndex.value=pageIndex.value+1;
            }
            else{
                let service=industryChain.value.filter(t=>t.id==subChainId)[0].children.filter(t=>t.isSel)[0];
                drawerTitle.value='产品服务—'+service.name;
                drawer.value=true;
                selServiceId.value=service.id;
                personList.value=[];
                pageIndex.value=1;
            }
            //getPersonListOfServiceFun(subChainId,selServiceId.value,pageSize.value,pageIndex.value,2)
        }
*/
       
        //加载数据
        getIndustryChainInfoFun();
        getServicesOfIndustryChainFun()
        //记录用户操作日志
        addUserLog(route.name,route.fullPath,'加载',JSON.stringify({chainId:route.query.id}));
        return{
            industryChainName,
            industryClassName,
            colors,
            drawer,
            //openDrawerFun,
            industryChain,
            getEpListOfServiceFun,
            getEpAndPersonOfServiceFun,
            push,
            //drawerTitle,
            //personList,
            //personCount
        }
    },
}
</script>
<style lang='scss' scoped>
@import '@/styles/variables.scss';
.grid-title{
    background-color: $themeColor;
    line-height: 30px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
}
.grid-content-h{
    margin-top:30px;
    height: 306px;
}
.grid-sub{
    display:flex;
    justify-content:center;
    .grid-content-ser{
        width:calc(25% - 10px);
        margin:0 5px;
    }
}
.grid-self{
    display:flex;
    justify-content:left;
    flex-wrap: wrap;
    align-content:flex-start;
    .grid-content-ser{
        width:calc(25% - 10px);
        margin:5px;
    }
}

.grid-struc{
    background: rgba(116,184,255,.8);
    padding: 5px 10px;
    width: 100%;
    display: inline-block;
    text-align: center;
    font-size: 16px;
    border: 1px solid rgba(255,255,255,.5);
    letter-spacing: 1px;
    border-radius: 2px;
    cursor: pointer;
}
.sub-ser{
    background: rgba(116,184,255,1);
    border: 1px solid rgba(255,255,255,.5);
    border-radius: 2px;
    line-height: 20px;
    text-align: center;
    margin:0;
}
.sub-ser-add{
    position: absolute;
    top: 15px;
    right: -12px;
    cursor: pointer;
}
.grid-ep{
    display:flex;
    margin:0;
    padding:0;
    border-bottom:1px solid;
    .grid-ep-name{
        width: calc(23.07% - 10px);
        border: 1px solid rgba(116, 184, 255, 0.1);
        margin: 5px;
        padding:3px 5px;
        background-color: rgba(116, 184, 255, 0.1);
        line-height:15px; 
    }
    .grid-ep-content{
        width:calc(39% - 10px);;
        margin: 5px;
        height:45px;
        line-height:15px; 
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 3;
    }
    .grid-ep-info{
        width: calc(23.5% - 10px);
        margin: 5px;
        padding:3px 5px;
        line-height:15px; 
    }
}
.grid-ex{
    display:flex;
    margin:0 5px;
    padding: 5px;
   
    .grid-ex-img{
        width: calc(37.5% - 10px);
        border: 1px solid rgba(116, 184, 255, 0.1);
        margin: 3px 10px 4px 0px;
        .el-image{
            height: 100%;
            width: 100%;
        }
    }
    .grid-ex-content{
        width:63.5%;
        height:140px;
        line-height:20px; 
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 7;
    }
}
::v-deep .el-dropdown{
    color: $specialtext-color;
}

::v-deep  .el-drawer__header {
    color: $specialtext-color;
    font-weight: bold;
    font-size: 20px;
}
::v-deep .el-drawer__body{
    height: calc(100% - 70px);/****减掉标题的高度 */
}
.infinite-list-wrapper {
  height: 100%;
  overflow: auto;
}
.infinite-list-wrapper .list-loading{
    text-align: center;
}
.infinite-list-wrapper ul {
  padding: 0;
  margin: 20px;
  list-style: none;
  p{
      color: #000;
  }
}

.infinite-list-wrapper .list-item {
    font-size: 14px;
    margin-top: 20px;
    display: flex;
//   align-items: center;
//   justify-content: center;
    color: #000;
}
::v-deep .el-link.el-link--default {
    color: $specialtext-color;
}
::v-deep .el-link.el-link--default:hover {
    color:darken($specialtext-color,10%);
}
</style>